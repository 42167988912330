import * as React from 'react';
import Layout from '../components/layout';

const headingAccentStyles = {
  fontWeight: 'bold',
};
const paragraphStyles = {
  marginBottom: 48,
};

const siteDescription = `
  Welcome to Longpoint! We are developers creating a variety of projects that intersect
  technology and our personal interests.
`;

const IndexPage = ({ location }) => {
  React.useEffect(() => {
    if (location.search.includes('contact=success')) {
      alert('Thank you for your message!');
    }
  }, []);

  return (
    <Layout title="Longpoint Home" rel="/" description={siteDescription}>
      <p style={paragraphStyles}>{siteDescription}</p>
      <p style={headingAccentStyles}>Check back later for updates!</p>
    </Layout>
  );
};

export default IndexPage;
